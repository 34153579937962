import React from 'react';
import {graphql, PageProps} from 'gatsby';
import {Layout} from '../../components/Layout';
import PageWidth from '../../components/PageWidth';
import HeaderImage from '../../components/HeaderImage';
import SEO from '../../components/SEO';
import {PageWrapper, RichText} from '../../styles/page';
import SoundCloudPlayer from '../../components/SoundCloudPlayer';
import AmpifySoundsLogo from '../../components/svg/AmpifySounds';
import renderRichText from '../../utils/rich-text-renderer';
import {getImageFromData, getPageData} from '../../utils';
import {AmpifySoundsQuery} from './__generated__/AmpifySoundsQuery';

const AmpifySounds = (props: PageProps<AmpifySoundsQuery>) => {
  const data = getPageData(props.data);
  const headerImage = getImageFromData(data, 'headerImage');

  return (
    <Layout>
      <SEO title={data.pageTitle} />
      <HeaderImage src={headerImage}>
        <AmpifySoundsLogo />
      </HeaderImage>
      <PageWrapper>
        <PageWidth narrow>
          <RichText>{renderRichText(data.pageContentTop)}</RichText>
          <SoundCloudPlayer
            title="Ampify Sounds Playlist"
            playlist="https://soundcloud.com/ampifymusic/sets/ampify-sounds"
          />
          <RichText>{renderRichText(data.pageContentBottom)}</RichText>
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query AmpifySoundsQuery {
    en: allContentfulAmpifyWebAmpifySounds(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          metaKeywords
          metaDescription
          pageTitle
          headerImage {
            ...AmpifyHeaderImage
          }
          pageContentTop {
            ...AmpifyPageContentTop
          }
          pageContentBottom {
            ...AmpifyPageContentBottom
          }
          soundcloudEmbedUrl
        }
      }
    }
  }
`;

export default AmpifySounds;
